// If you need to support IE11 uncomment the imports below
//import "react-app-polyfill/ie11";
//import "core-js/stable"; 
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";

import App from './App';

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig, loginApiRequest } from "./authConfig";
import { ThemeContextProvider } from './styles/ThemeContextProvider';

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
}, error => {
  console.log('error', error);
});

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

axios.interceptors.request.use(
  async (response) => {
    const account = msalInstance.getAllAccounts()[0];
    // console.log(account)
    if (account) {
      await msalInstance.initialize();
      const msalResponse = await msalInstance.acquireTokenSilent({
        ...loginApiRequest,
        account: account,
      });
      response.headers['Authorization'] = `Bearer ${msalResponse.accessToken}`;
    }
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  if (error instanceof InteractionRequiredAuthError) {
    msalInstance.loginRedirect(loginApiRequest);
    //msalInstance.acquireTokenRedirect(loginApiRequest);
  }
  return Promise.reject(error);
});

root.render(
  <Router>
    <ThemeContextProvider>
      <App pca={msalInstance} />
    </ThemeContextProvider>
  </Router>
);
