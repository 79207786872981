import React, { useState, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, useTheme } from '@mui/material';
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import EditInternalInfo from './EditInternalInfo';

export default function InternalInfoTable({id, type, userCanEdit}) {
  const borderStyle = { border: null };
  const externalBorderStyle = {borderTop: '2px solid #BEBEBE', borderRight: '2px solid #BEBEBE', borderBottom: '2px solid #BEBEBE', borderLeft: '2px solid #BEBEBE'};
  const [rowId, setRowId] = useState(id);
  const [isLoading, setIsLoading] = useState(true);
  const [internalInfo, setInternalInfo] = useState({});
  const [prodSummaryData, setProdSummaryData] = useState({});
  const [productInfo, setProductInfo] = useState({});
  const [riserCount, setRiserCount] = useState();
  const [siteBuild, setSiteBuild] = useState();
  const [programmer, setProgrammer] = useState('');
  const [installTester, setInstallTester] = useState('');
  const [timerTest, setTimerTest] = useState('');
  const [timeZone, setTimeZone] = useState('');
  const [productConfigData, setProductConfigData] = useState();
  const [dynamicHeight, setDynamicHeight] = useState('');
  const theme = useTheme();

  useEffect(() => {
    if (type == 'ED') {
      setDynamicHeight('500px');
    }
    else {
      setDynamicHeight('350px');
    }
  }, [type]);

  const fetchData = async () => {
    try {
      const internalInfoEndpoint = `api/InternalInfo/getDeviceLevelInternalInfo/${id}`;
      const response = await axios.get(internalInfoEndpoint);
      setInternalInfo(response.data);
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      console.error("Error retrieving data", error); 
    }
  };

  const fetchProductData = async () => {
    try {
      const productInfoEndpoint = `api/ProductSummary/getProductSummaryFromPcId/${id}`;
      const response = await axios.get(productInfoEndpoint);
      setProductInfo(response.data);
      setTimeZone(response.data.timeZone);
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      console.error("Error retrieving data", error); 
    }
  };

  const fetchProgrammer = async () => {
    try {
      const programmerFromPcEndpoint = `api/ProductConfiguration/productConfig/${id}`;
      const response = await axios.get(programmerFromPcEndpoint);
      setProgrammer(response.data.programmer);
      setInstallTester(response.data.installTester);
      setTimerTest(response.data.timerTest);
      setProductConfigData(response.data);
    }
    catch (error) {
      console.error("Error fetching programmer.");
    }
  };

  const fetchProdSummary = async () => {
    try {
      const prodSummaryFromPcEndpoint = `api/ProductSummary/getProductSummaryFromPcId/${id}`;
      const response = await axios.get(prodSummaryFromPcEndpoint);
      setProdSummaryData(response.data);

    }
    catch (error) {
      console.error("Error fetching prod summary info.");
    }
  };

  useEffect(() => {
    fetchData();
    fetchProductData();
    fetchProgrammer();
    fetchProdSummary();
  }, [rowId]);

  useEffect(() => {
    setRiserCount(JSON.stringify(productInfo.riserCount));
  }, [productInfo]);

  useEffect(() => {
    switch (riserCount) {
      case '1':
        setSiteBuild('Single');
        break;
      case '2':
        setSiteBuild('Double');
        break;
      case '3':
        setSiteBuild('Triple');
        break;
      case '4':
        setSiteBuild('Quad');
        break;
      default:
        setSiteBuild('');
    };
  }, [riserCount]);

  const offsetDate = (date) => {
    return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
  }
  
  return (
    <TableContainer 
      component={Paper}
    >
      <Table
        style={{ 
          borderCollapse: 'collapse', 
          ...externalBorderStyle,
          minHeight: type === 'ED' ? '590px' : '350px',
          maxHeight: type === 'ED' ? '590px' : '350px',
          overflow: 'auto',
        }} 
        sx={{
          '& .MuiTableCell-root': {
            padding: '4px', 
            fontSize: '0.875rem',
            height: '33px',
            backgroundColor: 'inherit',
          },
          '& .MuiTableCell-head': {
            fontWeight: 'bold',
            backgroundColor: 'inherit',
          },
          '& .MuiTableRow-root': {
            height: '24px', 
          },
          '& th, & td': {
            whiteSpace: 'nowrap',
            textAlign: 'center', 
          },
          '&:hover': { backgroundColor: theme.palette.mode === 'light' ? '#F3F3F3' : '#252525' }
        }}
      >
        
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} align="center" style={{ ...borderStyle, fontWeight: 'bold' }}>
              <Box display="inline-flex" alignItems="center" justifyContent="space-around" width="auto" marginLeft='15%'>
                <Typography variant='h6' gutterBottom style={{ fontWeight: 'bold', fontSize: '27px', paddingRight: userCanEdit ? null : '25%' }}>
                  Internal Information
                </Typography>
              {!isLoading && internalInfo && (
                userCanEdit ?
                <Box ml={2}>
                  <EditInternalInfo 
                    internInfo={internalInfo} 
                    onSaveProgrammer={fetchProgrammer} 
                    onSaveData={fetchData} 
                    prog={programmer}
                    ft={installTester}
                    tt={timerTest}
                  />
                </Box>
                : null
              )}
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
          <TableRow>
            <TableCell style={borderStyle}>Programmer:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>{programmer}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Project Manager:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>
              {prodSummaryData.projectManager}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Install Tester:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>
              {installTester}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Site Build:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>
              {siteBuild}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Ship Date:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>
              {internalInfo.shipDate ? (
                offsetDate(new Date(internalInfo.shipDate)).toLocaleString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                })
              ) : (
                ""
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Install Date:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>
              {internalInfo.installDate ? (
                offsetDate(new Date(internalInfo.installDate)).toLocaleString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                })
              ) : (
                ""
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Monitoring Start Date:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>
              {internalInfo.activationStartDate}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Central Station:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>
              {internalInfo.centralStation}
              </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Time Zone:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>
              {timeZone && timeZone !== 'Unknown' ? timeZone : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle}>Timer Test:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle}>
              {timerTest}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

