import React, { useEffect, useState } from "react";
import { Grid, Skeleton, Typography, Table, TableBody, TableRow, TableCell, Paper, Tabs, Tab, Box, Card, Stack } from "@mui/material";
import { projectManageAxios as axios } from "../utils/AxiosUtility";
import PropTypes from 'prop-types';
import { useSearchParams } from "react-router-dom";
import EditSiteInfoModal from "./EditSiteInfo";

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '10px' }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function SiteInfo() {
  const [tabValue, setTabValue] = new useState(0);
  const [siteInfoData, setSiteInfoData] = useState({});
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    axios
      .get(`/api/Onboarding/siteInfo/${searchParams.get("id")}`)
      .then((response) => {
        setSiteInfoData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error.message) {
          alert(error.message);
        } else {
          alert("Error fetching site info.");
        }
      });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  const isOnboardingURL = window.location.pathname.includes("/onboarding");

  const skeletonRows = Array.from({ length: 4 }).map((_, index) => (
    <TableRow key={index}>
      <TableCell align="center">
        <Skeleton animation="wave" variant="text" width={150} />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" variant="text" width={150} />
      </TableCell>
      <TableCell align="center">
        <Skeleton animation="wave" variant="text" width={100} />
      </TableCell>
    </TableRow>
  ));

  return (
    <>
      <Typography variant="h5" gutterBottom style={{ display: "flex", alignItems: "center" }}>
        <div style={{ paddingLeft: "160px" }}>
          Site Info
        </div>
        <div style={{ flex: 1 }}></div>
        {siteInfoData["projectId"] && <EditSiteInfoModal siteInfoData={siteInfoData} reload={fetchData}/>}
      </Typography>
      {loading ? (
        <Table>
          <TableBody>{skeletonRows}</TableBody>
        </Table>
      ) : (
        <Grid container spacing={0} sx={{ minHeight: "50vh" }}>
          <Grid item sm={12}>
            <Paper sx={{ padding: "16px 16px 10px 16px", minWidth: "100%", mb: 1 }}>
              <Stack direction={"row"}>
                <div style={{ minWidth: "40%" }}>
                  <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                    Project ID:&nbsp;
                  </Typography>
                  <Typography variant="body3" align="center">
                    {siteInfoData["projectId"]}
                  </Typography>
                </div>
                {siteInfoData["projectManager"] !== null ? (
                  <div style={{ minWidth: "60%" }}>
                    <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                      PM:&nbsp;
                    </Typography>
                    <Typography variant="body3" align="center">
                      {siteInfoData["projectManager"]}
                    </Typography>
                  </div>
                  ) : (
                    <div style={{ minWidth: "60%" }}>
                      <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                        PM:&nbsp;
                      </Typography>
                      <Typography variant="body3" align="center">
                        NA
                      </Typography>
                    </div>
                  )
                } 
              </Stack>

              <Stack direction={"column"}>
                <div style={{ minWidth: "100%" }}>
                  <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                    Customer:&nbsp;
                  </Typography>
                  <Typography variant="body3" align="center">
                    {siteInfoData["cmName"]}
                  </Typography>
                </div>
                <div style={{ minWidth: "100%" }}>
                  <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                    Site:&nbsp;
                  </Typography>
                  <Typography variant="body3" align="center">
                    {siteInfoData["csName"]}
                  </Typography>
                </div>
              </Stack>

              {siteInfoData["csAccountManager"] !== null ? (
                <div style={{ minWidth: "100%" }}>
                  <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                    Account Manager:&nbsp;
                  </Typography>
                  <Typography variant="body3" align="center">
                    {siteInfoData["csAccountManager"]}
                  </Typography>
                </div>
              ) : (
                <div style={{ minWidth: "100%" }}>
                  <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                    Account Manager:&nbsp;
                  </Typography>
                  <Typography variant="body3" align="center">
                    NA
                  </Typography>
                </div>
              )}

              {siteInfoData["units"] && siteInfoData["units"].length > 0 ? (
                <div style={{ minWidth: "100%" }}>
                  <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                    Product Name:&nbsp;
                  </Typography>
                  <Typography variant="body3" align="center">
                    {siteInfoData["units"]}
                  </Typography>
                </div>
                ) : (
                  <div style={{ minWidth: "100%" }}>
                    <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                      Product Name:&nbsp;
                    </Typography>
                    <Typography variant="body3" align="center">
                      NA
                    </Typography>
                  </div>
                )
              }
              
              {siteInfoData["centralStation"] !== null ? (
                <div style={{ minWidth: "100%" }}>
                  <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                    Central Station:&nbsp;
                  </Typography>
                  <Typography variant="body3" align="center">
                  {siteInfoData["centralStation"]}
                  </Typography>
                </div>
              ) : (
                <div style={{ minWidth: "100%" }}>
                  <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                    Central Station:&nbsp;
                  </Typography>
                  <Typography variant="body3" align="center">
                  NA
                  </Typography>
                </div>
              )}

              {siteInfoData["salesRep"] && siteInfoData["salesRep"].length > 0 ? (
                <div style={{ minWidth: "100%" }}>
                  <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                    Sales Rep:&nbsp;
                  </Typography>
                  <Typography variant="body3" align="center">
                    {siteInfoData["salesRep"]}
                  </Typography>
                </div>
                ) : (
                  <div style={{ minWidth: "100%" }}>
                    <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                      Sales Rep:&nbsp;
                    </Typography>
                    <Typography variant="body3" align="center">
                      NA
                    </Typography>
                  </div>
                )
              }

              <Stack direction={"row"}>
                <div style={{ minWidth: "60%" }}>
                  <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                    Install Value:&nbsp;
                  </Typography>
                  <Typography variant="body3" align="center">
                    {formatter.format(siteInfoData["installPrice"])}
                  </Typography>
                </div>
                <div style={{ minWidth: "40%" }}>
                  <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                    RMR:&nbsp;
                  </Typography>
                  <Typography variant="body3" align="center">
                    {formatter.format(siteInfoData["rmrValue"])}
                  </Typography>
                </div>
              </Stack>
            </Paper>

            <Grid item xs={12} style={{ minHeight: "55%", paddingTop: "0.5%", paddingBottom: "0.5%" }}>
              <Card>
                <Box sx={{ width: "100%" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} >
                    <Tabs value={tabValue} onChange={handleTabChange}>
                      <Tab label="Address" {...tabProps(0)} />
                      <Tab label="Counts" {...tabProps(1)} />
                      {!isOnboardingURL ?
                        <Tab label="Install" {...tabProps(2)} />
                        : null
                      }
                    </Tabs>
                  </div>
                  <CustomTabPanel value={tabValue} index={0}>
                    <div style={{ minWidth: "100%", padding: "0px 10px 5px 10px" }}>
                      <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                        {siteInfoData["csAddress1"]},&nbsp;{siteInfoData["csAddress2"]}
                      </Typography>
                    </div>
                    <div style={{ minWidth: "100%", padding: "0px 10px 5px 10px" }}>
                      <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                        {siteInfoData["csCity"]},&nbsp;{siteInfoData["csState"]}&nbsp;&nbsp;{siteInfoData["csZip"]}
                      </Typography>
                    </div>
                    <div style={{ minWidth: "100%", padding: "0px 10px 5px 10px" }}>
                      <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                        {siteInfoData["csCountry"]}
                      </Typography>
                    </div>
                  </CustomTabPanel>

                  <CustomTabPanel value={tabValue} index={1}>
                    <Stack sx={{ padding: "0px 10px 5px 10px" }} direction={"row"}>
                      <div style={{ minWidth: "50%" }}>
                        <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                          BSU Count:&nbsp;
                        </Typography>
                        <Typography variant="body3" align="center">
                          {siteInfoData["bsuCount"] ? siteInfoData["bsuCount"] : 0}
                        </Typography>
                      </div>
                      <div style={{ minWidth: "50%" }}>
                        <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                          OW Count:&nbsp;
                        </Typography>
                        <Typography variant="body3" align="center">
                          {siteInfoData["onWatchCount"] ? siteInfoData["onWatchCount"] : 0}
                        </Typography>
                      </div>
                    </Stack>

                    <Stack sx={{ padding: "0px 10px 5px 10px" }} direction={"row"}>
                      <div style={{ minWidth: "50%" }}>
                        <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                          CF Count:&nbsp;
                        </Typography>
                        <Typography variant="body3" align="center">
                          {siteInfoData["cfCount"] ? siteInfoData["cfCount"] : 0}
                        </Typography>
                      </div>
                      <div style={{ minWidth: "50%" }}>
                        <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                          ED Count:&nbsp;
                        </Typography>
                        <Typography variant="body3" align="center">
                          {siteInfoData["edCount"] ? siteInfoData["edCount"] : 0}
                        </Typography>
                      </div>
                    </Stack>

                    <Stack sx={{ padding: "0px 10px 5px 10px" }} direction={"row"}>
                      <div style={{ minWidth: "50%" }}>
                        <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                          Riser Count:&nbsp;
                        </Typography>
                        <Typography variant="body3" align="center">
                          {siteInfoData["totalRiserCount"] ? siteInfoData["totalRiserCount"] : 0}
                        </Typography>
                      </div>
                      <div style={{ minWidth: "50%" }}>
                        <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                          Thermal Count:&nbsp;
                        </Typography>
                        <Typography variant="body3" align="center">
                          {siteInfoData["totalThermalCount"] ? siteInfoData["totalThermalCount"] : 0}
                        </Typography>
                      </div>
                    </Stack>

                    <div style={{ minWidth: "30%", paddingLeft: "10px" }}>
                      <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                        IR Count:&nbsp;
                      </Typography>
                      <Typography variant="body3" align="center">
                        {siteInfoData["totalIrCount"] ? siteInfoData["totalIrCount"] : 0}
                      </Typography>
                    </div>
                  </CustomTabPanel>

                  <CustomTabPanel value={tabValue} index={2}>
                    <div style={{ minWidth: "100%", padding: "0px 10px 5px 10px" }}>
                      <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                        Install Date:&nbsp;
                      </Typography>
                      <Typography variant="body3" align="center">
                        {siteInfoData["installDate"] ? formatDate(siteInfoData["installDate"]) : "-"}
                      </Typography>
                    </div>
                    <div style={{ minWidth: "100%", padding: "0px 10px 5px 10px" }}>
                      <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                        Shipped Date:&nbsp;
                      </Typography>
                      <Typography variant="body3" align="center">
                        {siteInfoData["shippedDate"] ? formatDate(siteInfoData["shippedDate"]) : "-"}
                      </Typography>
                    </div>
                    <div style={{ minWidth: "100%", padding: "0px 10px 5px 10px" }}>
                      <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                        Install Team:&nbsp;
                      </Typography>
                      <Typography variant="body3" align="center">
                        {siteInfoData["installTeam"] ? siteInfoData["installTeam"] : "-"}
                      </Typography>
                    </div>
                    <div style={{ minWidth: "100%", padding: "0px 10px 5px 10px" }}>
                      <Typography variant="h7" align="center" style={{ fontWeight: "bold" }}>
                        Permit Required:&nbsp;
                      </Typography>
                      <Typography variant="body3" align="center">
                        {siteInfoData["permitRequired"] ? siteInfoData["permitRequired"] : "-"}
                      </Typography>
                    </div>
                  </CustomTabPanel>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
