import { Routes, Route, useNavigate } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";

import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";

import { PageLayout } from "./components/PageLayout";
import { Home } from "./pages/Home";
import { Logout } from "./pages/Logout";

import { useThemeContext } from "./styles/ThemeContextProvider";
import { ProjectManageTaskSection } from "./pages/ProjectManagement/ProjectManageTaskSection.jsx";
import { ProjectManageMainPage } from "./pages/ProjectManagement/ProjectManageMainPage.jsx";
// import { ManMainPage } from "./pages/manufacturing/ManMainPage";
import { InvMainPage } from "./pages/Inventory/InvMainPage";
import { WIPMainPage } from "./pages/WIP/WIPMainPage";
import { TimeSheet } from "./pages/TimeSheet/TimeSheet";
import { ManMainPage } from "./pages/Manufacturing/ManMainPage";
// import { ResourcePlanning } from "./pages/manufacturing/ResourcePlanning";
// import { BuildDetails } from "./pages/manufacturing/Builddetails";
// import { QualityAssurance } from "./pages/manufacturing/QualityAssurance";
import NotFound from "./pages/404";
import { Employees } from "./pages/Employees";
import './App.css'
import BayAssignment from "./pages/Manufacturing/BayAssignment";
import PatchNotes from "./components/PatchNotes";

import { SiteMainPage } from "./pages/SiteInfo/SiteInfoMainPage";
import { SiteOverview } from "./pages/SiteInfo/SiteOverview";
import { DeviceMainPage } from "./pages/DeviceInfo/DeviceInfoMainPage";
import { MasterInfo } from "./pages/DeviceInfo/MasterDeviceInfo";

function App({ pca }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  const { theme } = useThemeContext();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MsalProvider instance={pca}>
        <PageLayout>
          <Pages/>
        </PageLayout>
      </MsalProvider>
    </ThemeProvider>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path="/projectManagement" element={<ProjectManageTaskSection />} />
      <Route path="/projectManagement/project" element={<ProjectManageMainPage />} />
      {/* <Route path="/manufacturing" element={<ManMainPage />} />
      <Route path="/manufacturing/resourcePlanning" element={<ResourcePlanning />} />
      <Route path="/manufacturing/buildDetails" element={<BuildDetails />} />
      <Route path="/manufacturing/qualityAssurance" element={<QualityAssurance />} /> */}
      <Route path="/inventory" element={<InvMainPage />} />
      <Route path="/employees" element={<Employees />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/" element={<Home />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/WIP" element={<WIPMainPage />} />
      <Route path="/Site" element={<SiteMainPage />} />
      <Route path="/Site/Overview" element={<SiteOverview />} />
      <Route path="/Device" element={<DeviceMainPage />} />
      {/* <Route path="/Device/Overview" element={<DeviceOverview />} /> */}
      <Route path="/Device/MasterDeviceInfo" element={<MasterInfo />} />
      <Route path="/Manufacturing" element={<ManMainPage />} />
      <Route path="/BayAssignment" element={<BayAssignment />} />
      <Route path="/PatchNotes" element={<PatchNotes />} />
      <Route path="/TimeSheet" element={<TimeSheet />} />
    </Routes>
  );
}

export default App;
