import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Modal, List, ListItem, Checkbox, ListItemButton, ListItemText, Stack, TextField, MenuItem, FormControl, InputLabel, Select, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Paper, Divider } from "@mui/material";
import { projectManageAxios as axios } from "../../../utils/AxiosUtility";
import AddContactModal from "../../Contacts/AddContactModal";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '90%',
    minWidth: '70%',
    overflow: 'scroll',
    overflowX: "hidden",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const buttonsStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
};

export function CMInvite({ projectId }) {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = useState([]);
    const [checked, setChecked] = React.useState([]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const fetchContacts = () => {
        const endpoint = 'api/Onboarding/siteLevelContacts/' + projectId;
        axios.get(endpoint)
            .then((response) => {
                const contactsData = Array.isArray(response.data) ? response.data : [];
                console.log(contactsData)
                setData(contactsData);
            })
            .catch((error) => {
                console.log(error);
                if (error.message) {
                    alert(error.message);
                } else {
                    alert("Error fetching contact list.");
                }
            });
    };

    useEffect(() => {
        fetchContacts();
        // eslint-disable-next-line
    }, []);

    const handleContactAdded = () => {
        fetchContacts();
    }

    const handleSubmit = () => {
        if (checked.length > 0) {
            let contacts = [];
            checked.forEach(index => {
                contacts.push(data[index])
            });
            axios.post("/api/Coordination/sendEmailInvite", contacts)
                .then((response) => {
                    alert("Invites sent")
                })
                .catch((error) => {
                    console.log(error);
                    alert("Error sending invites.");
                });
        } else {
            alert("Please select atleast one contact!")
        }

    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button variant="contained" onClick={handleOpen}>Select</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        variant="h5"
                        gutterBottom
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <div >Select Contacts</div>
                        <div style={{ flex: 1 }}></div>
                        <AddContactModal onContactAdded={handleContactAdded} />
                    </Typography>
                    <List sx={{ width: '100%' }}>
                        <ListItem
                            sx={{ minWidth: "5%" }}
                            key={99}
                            disablePadding
                        >
                            <ListItemText sx={{ minWidth: "20%" }} id={0} primary={`Name`} />
                            <ListItemText sx={{ minWidth: "20%" }} id={1} primary={`Title`} />
                            <ListItemText sx={{ minWidth: "20%" }} id={2} primary={`Phone`} />
                            <ListItemText sx={{ minWidth: "20%" }} id={3} primary={`Email`} />
                            <ListItemText sx={{ minWidth: "20%" }} id={4} primary={`Contact Type`} />
                        </ListItem>
                    </List>
                    <Divider />
                    <List sx={{ width: '100%' }}>
                        {data.map((contact, index) => {
                            const labelId = `checkbox-list-secondary-label-${contact.csContactId}`;
                            return (
                                <ListItem
                                    sx={{ minWidth: "5%", paddingBottom: "1%" }}
                                    key={contact.csContactId}
                                    secondaryAction={
                                        <Checkbox
                                            edge="end"
                                            onChange={handleToggle(index)}
                                            checked={checked.includes(index)}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    }
                                    disablePadding
                                >
                                    <ListItemText sx={{ minWidth: "20%" }} id={labelId} primary={`${contact.csContactName}`} />
                                    <ListItemText sx={{ minWidth: "20%" }} id={labelId} primary={`${contact.csContactRole}`} />
                                    <ListItemText sx={{ minWidth: "20%" }} id={labelId} primary={`${contact.csContactPhone}`} />
                                    <ListItemText sx={{ minWidth: "20%" }} id={labelId} primary={`${contact.csContactEmail}`} />
                                    <ListItemText sx={{ minWidth: "20%" }} id={labelId} primary={`${contact.csContactType}`} />
                                </ListItem>
                            );
                        })}
                    </List>
                    <div style={buttonsStyle}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" onClick={handleSubmit}>Send</Button>
                    </div>
                </Box>
            </Modal>
        </>
    );
}