import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import NetworkInfoTable from './NetworkInfo';
import InternalInfoTable from './InternalInfo';
import SiteInfoTable from './SiteInfo';
import ServiceLogTable from './ServiceLog';
import AirgasTable from './Airgas';
import ProductInfoTable from './ProductInfo';

const gridItemProps = {
  xs: 4,
  container: true,
  justifyContent: "center",
};

export default function TopDeviceSection({ id, prodConfigId, prodType, userCanEdit }) {
  const [productType, setProductType] = useState(prodType);
  const [productConfigId, setProductConfigId] = useState(prodConfigId);

  useEffect(() => {
    setProductType(prodType);
    setProductConfigId(prodConfigId);
  }, [id, prodConfigId, prodType]);

  function RenderCF() {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item {...gridItemProps}>
            <Box sx={{ padding: 2, minHeight: '150px', width: '100%' }}>
              <NetworkInfoTable id={prodConfigId} userCanEdit={userCanEdit} />
            </Box>
          </Grid>
          <Grid item {...gridItemProps}>
            <Box sx={{
              padding: 2,
              minHeight: '150px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <ServiceLogTable id={prodConfigId} userCanEdit={userCanEdit} />
            </Box>
          </Grid>
          <Grid item {...gridItemProps}>
            <Box sx={{
              padding: 2,
              minHeight: '150px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <SiteInfoTable id={prodConfigId} userCanEdit={userCanEdit} />
            </Box>
          </Grid>

          <Grid container item xs={12} justifyContent="space-between">
            <Grid item {...gridItemProps} sx={{paddingLeft: '7%'}} xs={5}>
              <Box sx={{
                padding: 2,
                minHeight: '150px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <InternalInfoTable id={prodConfigId} type={prodType} userCanEdit={userCanEdit} />
              </Box>
            </Grid>
            <Grid item {...gridItemProps} sx={{paddingRight: '7%'}} xs={5}>
              <Box sx={{
                padding: 2,
                minHeight: '150px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <ProductInfoTable id={prodConfigId} type={prodType} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  function RenderBSU() {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item {...gridItemProps}>
            <Box sx={{ padding: 2, minHeight: '150px', width: '100%' }}>
              <NetworkInfoTable id={prodConfigId} userCanEdit={userCanEdit} />
            </Box>
          </Grid>
          <Grid item {...gridItemProps}>
            <Box sx={{
              padding: 2,
              minHeight: '150px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <ServiceLogTable id={prodConfigId} userCanEdit={userCanEdit} />
            </Box>
          </Grid>
          <Grid item {...gridItemProps}>
            <Box sx={{
              padding: 2,
              minHeight: '150px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <SiteInfoTable id={prodConfigId} userCanEdit={userCanEdit} />
            </Box>
          </Grid>

          <Grid item {...gridItemProps}>
            <Box sx={{ padding: 2, minHeight: '150px', width: '100%' }}>
              <InternalInfoTable id={prodConfigId} type={prodType} userCanEdit={userCanEdit} />
            </Box>
          </Grid>
          <Grid item {...gridItemProps}>
            <Box sx={{
              padding: 2,
              minHeight: '150px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <ProductInfoTable id={prodConfigId} type={prodType} />
            </Box>
          </Grid>
          <Grid item {...gridItemProps}>
            <Box sx={{
              padding: 2,
              minHeight: '150px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <AirgasTable pcId={prodConfigId} userCanEdit={userCanEdit} />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  function RenderOW() {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item {...gridItemProps}>
            <Box sx={{
              padding: 2,
              minHeight: '150px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <NetworkInfoTable id={prodConfigId} userCanEdit={userCanEdit} />
            </Box>
          </Grid>
          <Grid item {...gridItemProps}>
            <Box sx={{
              padding: 2,
              minHeight: '150px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <ServiceLogTable id={prodConfigId} userCanEdit={userCanEdit} />
            </Box>
          </Grid>
          <Grid item {...gridItemProps}>
            <Box sx={{
              padding: 2,
              minHeight: '150px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <SiteInfoTable id={prodConfigId} userCanEdit={userCanEdit} />
            </Box>
          </Grid>

          <Grid container item xs={12} justifyContent="space-between">
            <Grid item {...gridItemProps} sx={{paddingLeft: '7%'}} xs={5}>
              <Box sx={{
                padding: 2,
                minHeight: '150px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <InternalInfoTable id={prodConfigId} type={prodType} userCanEdit={userCanEdit} />
              </Box>
            </Grid>
            <Grid item {...gridItemProps} sx={{paddingRight: '7%'}} xs={5}>
              <Box sx={{
                padding: 2,
                minHeight: '150px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <ProductInfoTable id={prodConfigId} type={prodType} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  function RenderED() {
    return (
      <>
        <Grid container spacing={2} justifyContent="space-around">
          <Grid item {...gridItemProps}>
            <Box sx={{
              padding: 2,
              minHeight: '150px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <NetworkInfoTable id={prodConfigId} userCanEdit={userCanEdit} />
            </Box>
          </Grid>
          <Grid item {...gridItemProps}>
            <Box sx={{
              padding: 2,
              minHeight: '150px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <ProductInfoTable id={prodConfigId} type={prodType} />
            </Box>
          </Grid>
          <Grid item {...gridItemProps}>
            <Box sx={{
              padding: 2,
              minHeight: '150px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <InternalInfoTable id={prodConfigId} type={prodType} userCanEdit={userCanEdit} />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderTemplate = () => {
    switch (productType) {
      case 'CF':
        return RenderCF();
      case 'OW':
        return RenderOW();
      case 'BSU':
        return RenderBSU();
      case 'ED':
        return RenderED();
      default:
        return <Typography>Unknown Device Type</Typography>
    }
  };

  return renderTemplate();
}


